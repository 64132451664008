import apiCar from 'utils/apiCar';
import {removeUndefinedObject} from 'helpers';
import {createAppAsyncThunk, handleThunkError} from 'stores/helpers';
import type {PagingParams, PagingResponseData} from 'interfaces/shared';
import type {
  IStation,
  IStationImage,
  IStationOpenProfile,
  IStationOpenTimes,
  IStationRequest,
  StationOpenProfileRequest,
  StationOpenTimesRequest,
} from './types';

// #region Station
export const getStationAction = createAppAsyncThunk(
  'getStationAction',
  async (params: PagingParams<{name: string} | void>, thunkApi) => {
    try {
      const cleanParams = removeUndefinedObject(params);
      const result = await apiCar.get<PagingResponseData<IStation>>('api/stations', cleanParams);
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);
export const getStationDetailAction = createAppAsyncThunk('getStationDetailAction', async (id: number, thunkApi) => {
  try {
    const result = await apiCar.get<IStation>(`api/stations/${id}`);
    return result;
  } catch (error) {
    return handleThunkError(thunkApi, error);
  }
});
export const addStationAction = createAppAsyncThunk('addStationAction', async (arg: IStationRequest, thunkApi) => {
  try {
    const result = await apiCar.postRaw<IStation>('api/stations', arg.body);
    if (arg.image?.length) {
      const addImagePromise = arg.image.map(
        async image => await thunkApi.dispatch(addStationImageAction({id: result.id, file: image.file!})),
      );
      await Promise.all(addImagePromise);
    }
    return result;
  } catch (error) {
    return handleThunkError(thunkApi, error, true);
  }
});
export const updateStationAction = createAppAsyncThunk(
  'updateStationAction',
  async (arg: IStationRequest, thunkApi) => {
    try {
      const result = await apiCar.putRaw<IStation>(`api/stations/${arg.id}`, arg.body);
      if (arg.image?.length) {
        const addImagePromise = arg.image.map(
          async image => await thunkApi.dispatch(addStationImageAction({id: result.id, file: image.file!})),
        );
        await Promise.all(addImagePromise);
      }
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
export const deleteStationAction = createAppAsyncThunk('deleteStationAction', async (id: number, thunkApi) => {
  try {
    return await apiCar.delete<IStationOpenTimes>(`api/stations/${id}`);
  } catch (error) {
    return handleThunkError(thunkApi, error, true);
  }
});
// #endregion

// #region Station Image
export const getStationImageAction = createAppAsyncThunk(
  'getStationImageAction',
  async (params: PagingParams<{station: number}>, thunkApi) => {
    try {
      return await apiCar.get<PagingResponseData<IStationImage>>('api/station_images', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);
export const addStationImageAction = createAppAsyncThunk(
  'addStationImageAction',
  async (params: {id: number; file: File}, thunkApi) => {
    try {
      return await apiCar.postForm<{}>(`api/stations/${params.id}/upload/photo`, {photo: params.file});
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
export const deleteStationImageAction = createAppAsyncThunk(
  'deleteStationImageAction',
  async (idImage: number, thunkApi) => {
    try {
      return await apiCar.delete<PagingResponseData<IStationImage>>(`api/station_images/${idImage}`);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);
// #endregion

// #region Station Profile
export const getStationProfileAction = createAppAsyncThunk(
  'getStationProfileAction',
  async (params: PagingParams<{name: string} | void>, thunkApi) => {
    try {
      return await apiCar.get<PagingResponseData<IStationOpenProfile>>('api/station_open_profiles', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const getStationProfileDetailAction = createAppAsyncThunk(
  'getStationProfileDetailAction',
  async (id: number, thunkApi) => {
    try {
      return await apiCar.get<IStationOpenProfile>(`api/station_open_profiles/${id}`);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const addStationProfileAction = createAppAsyncThunk(
  'addStationProfileAction',
  async (body: StationOpenProfileRequest, thunkApi) => {
    try {
      const resultStationProfile = await apiCar.postRaw<IStationOpenProfile>('api/station_open_profiles', body);
      const openTimesPromise = body.stationOpenTimesList.map(async item => {
        return await thunkApi.dispatch(
          addStationOpenTimesAction({...item, stationOpenProfile: resultStationProfile['@id']}),
        );
      });
      await Promise.all(openTimesPromise);
      return resultStationProfile;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);

export const updateStationProfileAction = createAppAsyncThunk(
  'updateStationProfileAction',
  async ({id, body}: {id: number; body: StationOpenProfileRequest}, thunkApi) => {
    try {
      return await apiCar.putRaw<IStationOpenProfile>(`api/station_open_profiles/${id}`, {
        name: body.name,
        nameVn: body.nameVn,
      });
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);

export const deleteStationProfileAction = createAppAsyncThunk(
  'deleteStationProfileAction',
  async (arg: {idProfile: number; openTimesList: IStationOpenTimes[]}, thunkApi) => {
    try {
      await apiCar.delete<IStationOpenProfile>(`api/station_open_profiles/remove/${arg.idProfile}`);
      if (arg.openTimesList.length > 0) {
        const openTimesPromise = arg.openTimesList.map(async item => {
          return await thunkApi.dispatch(deleteStationOpenTimesAction(item.id));
        });
        await Promise.all(openTimesPromise);
      }
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
// #endregion

// #region Station Open Time
export const getStationOpenTimeAction = createAppAsyncThunk(
  'getStationOpenTimeAction',
  async (params: PagingParams<{station: number}>, thunkApi) => {
    try {
      return await apiCar.get<PagingResponseData<IStationImage>>('api/station_images', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const addStationOpenTimesAction = createAppAsyncThunk(
  'addStationOpenTimesAction',
  async (body: StationOpenTimesRequest, thunkApi) => {
    try {
      return await apiCar.postRaw<IStationOpenTimes>('api/station_open_times/add', body);
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);

export const updateStationOpenTimesAction = createAppAsyncThunk(
  'updateStationOpenTimesAction',
  async ({id, body}: {id: number; body: Partial<StationOpenTimesRequest>}, thunkApi) => {
    try {
      return await apiCar.putRaw<IStationOpenTimes>(`api/station_open_times/${id}`, body);
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);

export const deleteStationOpenTimesAction = createAppAsyncThunk(
  'deleteStationOpenTimesAction',
  async (id: number, thunkApi) => {
    try {
      return await apiCar.delete<IStationOpenTimes>(`api/station_open_times/remove/${id}`);
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
// #endregion
