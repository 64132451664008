import type {AnyObject, PagingParams, PagingResponseData} from 'interfaces/shared';
import type {
  UserGroupRequest,
  IUser,
  UserRequest,
  UserToGroupRequest,
  IUserGroup,
  UpdateWalletRequest,
  IBalanceHistory,
} from 'stores/user/types';
import {createAppAsyncThunk, handleThunkError} from 'stores/helpers';
import {genAccountsAndPasswords, removeUndefinedObject} from 'helpers';
import api from 'utils/api';

export const getUserGroupsAction = createAppAsyncThunk(
  'getUserGroupsAction',
  async (params: PagingParams, thunkApi) => {
    try {
      return await api.get<PagingResponseData<IUserGroup>>('api/usergroups', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const queryUserGroupDetailAction = createAppAsyncThunk(
  'queryUserGroupDetailAction',
  async (idGroup: number, thunkApi) => {
    try {
      return await api.get<IUserGroup>(`api/group/${idGroup}`);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);

export const addUserGroupsAction = createAppAsyncThunk(
  'addUserGroupsAction',
  async (body: UserGroupRequest, thunkApi) => {
    try {
      const result = await api.postRaw<{id: number}>('api/add_group', body);
      thunkApi.dispatch(getUserGroupsAction());
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);

export const updateUserGroupAction = createAppAsyncThunk(
  'updateUserGroupAction',
  async (arg: {idGroup: number; body: UserGroupRequest}, thunkApi) => {
    try {
      const result = await api.postRaw<string>(`api/update_group/${arg.idGroup}`, arg.body);
      thunkApi.dispatch(getUserGroupsAction());
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);

export const removeUserGroupAction = createAppAsyncThunk('removeUserGroupAction', async (idGroup: number, thunkApi) => {
  try {
    const result = await api.postRaw<string>(`api/remove_group/${idGroup}`);
    thunkApi.dispatch(getUserGroupsAction());
    return result;
  } catch (error) {
    return handleThunkError(thunkApi, error, true);
  }
});

export const addUserToGroupsAction = createAppAsyncThunk(
  'addUserToGroupsAction',
  async (body: UserToGroupRequest, thunkApi) => {
    try {
      const result = await api.postRaw<[]>('api/add_user_group', body);
      thunkApi.dispatch(getUserGroupsAction());
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
export const removeUserFromGroupsAction = createAppAsyncThunk(
  'addUserToGroupsAction',
  async (body: {group_id: number; user_id: number}, thunkApi) => {
    try {
      const result = await api.delete<{}>('api/remove_user_group', {}, body);
      thunkApi.dispatch(getUserGroupsAction());
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
export const queryUserListAction = createAppAsyncThunk(
  'queryUserListAction',
  async (params: PagingParams<Partial<UserRequest>>, thunkApi) => {
    try {
      const brands = params.brands?.reduce((acc: AnyObject, value: number, index: number) => {
        acc[`bikeBrand[${index}]`] = value;
        return acc;
      }, {});
      delete params.brands;
      const cleanParams = removeUndefinedObject({...params, ...brands});
      return await api.get<PagingResponseData<IUser>>('api/users', cleanParams);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);
export const genUserAction = createAppAsyncThunk('genUserAction', async (limit: number, thunkApi) => {
  try {
    const listAccount = genAccountsAndPasswords(limit);
    if (listAccount.length > 0) {
      const listAccountCreatePromise = listAccount.map(
        async body => await api.postRaw<{userId: number}>('sign-up', body),
      );
      const resultCreateAccount = await Promise.all(listAccountCreatePromise);
      if (resultCreateAccount.length > 0) {
        const listAccountUpdateActivatedPromise = resultCreateAccount.map(
          async user => await api.putRaw(`api/users/${user.userId}`, {activated: true}),
        );
        const listAccountUpdateBalancePromise = resultCreateAccount.map(
          async user =>
            await thunkApi.dispatch(plusWalletAction({userId: user.userId, amount: 10000000, reason: 'Account Test'})),
        );
        await Promise.all([...listAccountUpdateActivatedPromise, ...listAccountUpdateBalancePromise]);
      }
    }
  } catch (error) {
    return handleThunkError(thunkApi, error, true);
  }
});
export const queryUserByIdAction = createAppAsyncThunk('queryUserByIdAction', async (id: number, thunkApi) => {
  try {
    return await api.get<IUser>(`api/users/${id}`);
  } catch (error) {
    return handleThunkError(thunkApi, error);
  }
});

export const plusWalletAction = createAppAsyncThunk('plusWalletAction', async (body: UpdateWalletRequest, thunkApi) => {
  try {
    const result = await api.putRaw<{message: string}>('api/admin/balance/plus_wallet', body);
    return result;
  } catch (error) {
    return handleThunkError(thunkApi, error, true);
  }
});

export const deductWalletAction = createAppAsyncThunk(
  'deductWalletAction',
  async (body: UpdateWalletRequest, thunkApi) => {
    try {
      const result = await api.putRaw<{message: string}>('api/admin/balance/deduct_wallet', body);
      return result;
    } catch (error) {
      return handleThunkError(thunkApi, error, true);
    }
  },
);
export const getBalanceHistoriesListAction = createAppAsyncThunk(
  'getBalanceHistoriesListAction',
  async (params: PagingParams, thunkApi) => {
    try {
      return await api.get<PagingResponseData<IBalanceHistory>>('api/balance_histories', params);
    } catch (error) {
      return handleThunkError(thunkApi, error);
    }
  },
);
