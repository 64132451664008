/** @format */

import {lazy} from 'react';
import {isLogged} from 'helpers/cookie';
import {Navigate} from 'react-router-dom';

export const publicRoutes = [
  {path: 'login', Component: lazy(() => import('pages/public/Login'))},
  {path: 'forgot-password', Component: lazy(() => import('pages/public/ForgotPwd'))},
  {path: '/', element: <Navigate to="/login" />},
  {path: '/*', element: <Navigate to="/login" />},
];

export const privateRoutes = [
  {path: '/', element: <Navigate to="/user" />},
  {path: '/*', element: <Navigate to="/user" />},
  // {path: 'dashboard', Component: lazy(() => import('pages/AppUser'))},
  {path: 'user/*', Component: lazy(() => import('pages/common/AppUser'))},
  {path: 'bike-boxes/*', Component: lazy(() => import('pages/charging/Box'))},
  {path: 'promotion/*', Component: lazy(() => import('pages/common/Promotion'))},
  {path: 'notification/*', Component: lazy(() => import('pages/common/Notification'))},
  {path: 'transaction/*', Component: lazy(() => import('pages/history'))},
  {path: 'report/*', Component: lazy(() => import('pages/common/Report'))},
  {path: 'content/static/*', Component: lazy(() => import('pages/common/Static'))},
  {path: 'content/faq/*', Component: lazy(() => import('pages/common/FAQ'))},
  {path: 'system/staff/*', Component: lazy(() => import('pages/system/User'))},
  {path: 'system/brand/*', Component: lazy(() => import('pages/system/Brand'))},
  {path: 'system/location/*', Component: lazy(() => import('pages/system/City'))},
  {path: 'system/immutable', Component: lazy(() => import('pages/system/Immutable'))},
  {path: 'car-boxes/*', Component: lazy(() => import('pages/charging/BoxCar'))},
  {path: 'location/*', Component: lazy(() => import('pages/charging/Location'))},
  {path: 'station/*', Component: lazy(() => import('pages/charging/Station'))},
  {path: 'charge-history/*', Component: lazy(() => import('pages/history/ChargeHistory'))},
  {path: 'topup-history/*', Component: lazy(() => import('pages/history/TopUpHistory'))},
  {path: 'payment-history/*', Component: lazy(() => import('pages/history/PaymentHistory'))},
  {path: 'user-balance-history/*', Component: lazy(() => import('pages/history/BalanceHistory'))},
  {path: 'station-profile/*', Component: lazy(() => import('pages/charging/StationProfile'))},
  {path: 'connector-profile/*', Component: lazy(() => import('pages/charging/ConnectorProfile'))},
  {path: 'connector-realtime-monitor', Component: lazy(() => import('pages/charging/ConnectorRTMonitor'))},
];

export const mainRoutes = [
  {
    path: '/',
    Component: lazy(() => import('App')),
    children: isLogged() ? privateRoutes : publicRoutes,
  },
];
